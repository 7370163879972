
  import { defineComponent, onMounted, reactive } from "vue";
  import ticketPresta from "@/views/still/fournisseur/ticketPresta.vue";
    import { useRouter } from "vue-router";
  
  import mAxiosApi from "@/api";
  
  export default defineComponent({
    name: "deploy-overview",
    components: {ticketPresta},
    setup() {
      
      const router = useRouter();
  
      //moment.default.locale("fr");
      const state = reactive({
        mailCra: '' as any,
        loaderEnabledGen : true,
      });
  
      onMounted(async () => {
        if (router.currentRoute.value.params.prestasoc) {
            await mAxiosApi.prototype.getAxios("/changeSociete/" + router.currentRoute.value.params.prestasoc);
            state.mailCra = router.currentRoute.value.params.mailcra;
            state.loaderEnabledGen = false;
        }
      });
  
      return {
        state,
      };
    },
  });
  